import axios from 'axios';

// 创建一个 axios 实例
const instance = axios.create({
  baseURL: '/api',  // 设置统一的请求前缀
  timeout: 10000,   // 设置超时时间
  headers: {
    'Content-type': 'application/x-www-form-urlencoded'
  },
});

// 所有参与活动的用户
export const getUserData = params => {
  return instance.post('/marketingService/web/enrollActivityUser/list', params);
};

// 奖项列表
export const getAwardsData = params => {
  return instance.post('/marketingService/web/enrollActivityAwards/list', params);
};

// 开奖
export const openLottery = params => {
  return instance.post('/marketingService/web/enrollActivityAwards/openLottery', params);
};

// export const imgBaseUrl = 'https://ydl-shop.oss-cn-guangzhou.aliyuncs.com/'
export const imgBaseUrl = 'https://dilong-shops.oss-cn-guangzhou.aliyuncs.com/'