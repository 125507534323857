<template>
  <div class="home_view">
    <div class="hottery_title">一道流水族星辉夜抽奖仪式</div>
    <div class="hottery_box">
      <div class="hb_left">
        <div class="left_img">
          <img
            class="images"
            :src="
              awardsList[indexImg] &&
              awardsList[indexImg].picture + '?' + Date.now()
            "
            alt=""
            srcset=""
          />
          <!-- <img class="images" :src="imgurl" alt="" srcset="" /> -->
        </div>
        <div class="awards_box">
          <div class="ab_title">
            {{ awardsList[indexImg] && awardsList[indexImg].name }}({{
              awardsList[indexImg] && awardsList[indexImg].awardsDesc
            }})
          </div>
          <div class="sub_title">
            <span>{{
              awardsList[indexImg] && awardsList[indexImg].prize
            }}</span>
            <span class="sub_tip">(此图仅供参考)</span>
          </div>
        </div>
        <div class="awards_num">
          中奖总数量：{{ awardsList[indexImg] && awardsList[indexImg].num }}(个)
        </div>
        <div class="select_box">
          <div class="ele_select">
            <el-select v-model="awardsValue" placeholder="选择奖项">
              <el-option
                v-for="(item, index) in awardsList"
                :key="index"
                :label="item.name"
                :value="index"
                @click.native="handleSelect(item, index)"
              >
              </el-option>
            </el-select>
          </div>
          <div class="input_box">
            <div class="input_tip">中奖数：</div>
            <el-input
              placeholder="输入中奖数"
              v-model="numValue"
              clearable
              @input="handleInput"
            >
            </el-input>
          </div>
        </div>
        <div class="button_box">
          <el-button type="success" round @click="startLottery"
            >开始抽奖</el-button
          >
          <el-button type="primary" round @click="stopLottery">停止</el-button>
        </div>
      </div>
      <div class="hb_right">
        <div class="hottery_list" v-if="isDisplayBox">
          <div class="hl_item" v-for="(item, index) in resList" :key="index">
            <div>{{ item.name.slice(0, 5) }}</div>
            <div class="hli_num">{{ item.code }}</div>
          </div>
        </div>
        <div class="win_box" v-else>
          <div class="win_title">获奖名单</div>
          <transition name="fade" tag="div" class="win_item_wrapper">
            <div class="win_user">
              <div
                class="win_item"
                v-for="(item, index) in winners"
                :key="item.id"
                :style="{ transition: `opacity 0.5s ${index * 0.1}s` }"
                v-show="item.show"
                @transitionend="transitionEnd(index)"
              >
                <div class="win_name">{{ item.name.slice(0, 3) }}</div>
                <div class="win_name">{{ item.code }}</div>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let that;
let suiInterval = null;
import {
  imgBaseUrl,
  getUserData,
  getAwardsData,
  openLottery,
} from "../api/api";
export default {
  data() {
    return {
      userList: [],
      isRotation: false,
      resList: [],
      awardsList: [],
      isStartAwards: false, //是否正在抽奖
      isDisplayBox: true, //是否显示名单
      awardsValue: null,
      numValue: "",
      winners: [],
      productImgJson: {
        "752d4db04acd49098c24128345838959":
          "752d4db04acd49098c24128345838959.png",
        cef1107971c040cca0ea3e6e1636c139:
          "cef1107971c040cca0ea3e6e1636c139.png",
        "92665597dea0452b9c4a704742fe9679":
          "92665597dea0452b9c4a704742fe9679.png",
        "85136a5fdc39413f885e4c5b4838b452":
          "85136a5fdc39413f885e4c5b4838b452.png",
        ed3038b4b8884a1f85e7fe7c6247824c:
          "ed3038b4b8884a1f85e7fe7c6247824c.png",
        "5b9709cf0c6d490c99a987f5c717b792":
          "5b9709cf0c6d490c99a987f5c717b792.png",
        ab916e56aa5040b5ab1b35d078f62f99:
          "ab916e56aa5040b5ab1b35d078f62f99.png",
        cf2d4837165e4e6ea5628b8f2e4a6057:
          "cf2d4837165e4e6ea5628b8f2e4a6057.png",
      },
      indexImg: 0,
      awardsId: null,
    };
  },
  methods: {
    // 输入框
    handleInput(e) {
      let num = that.awardsList[that.indexImg].num;
      let selectId = that.awardsList[that.indexImg].id;
      let awardsVal = localStorage.getItem(selectId) || 0;
      let lastNum = parseInt(num) - parseInt(awardsVal);
      if (e > lastNum) {
        this.$notify({
          title: "警告",
          message: "输入的中奖数过多",
          type: "warning",
        });
        return;
      }
      that.numValue = e;
    },
    // 奖项-单选
    handleSelect(item, index) {
      that.indexImg = index;
      that.numValue = null;
      that.awardsId = item.id;
      that.isDisplayBox = true;
    },
    // 过渡动画 start
    showWinners() {
      this.winners.forEach((winner, index) => {
        setTimeout(() => {
          // 使用 $set 来触发视图更新
          this.$set(this.winners, index, { ...winner, show: true });
        }, index * 1000); // 每个中奖者间隔0.1秒显示
      });
    },
    // 过渡动画 start
    transitionEnd(index) {
      // 过渡结束后触发，可以在这里进行一些清理工作
      console.log(`Transition ended for index ${index}`);
    },
    // 获取用户数据
    getenrollActivityUser() {
      getUserData().then((res) => {
        that.userList = res.data.data;
        that.resList = that.userList.slice(0, 15);
      });
    },
    // 获取奖项列表
    getenrollActivityAwards() {
      getAwardsData().then((res) => {
        let resultList = res.data.data;
        for (var i = 0; i < resultList.length; i++) {
          let localImg = that.productImgJson[resultList[i].id];
          if (localImg) {
            resultList[i].picture = require("@/assets/products/" + localImg);
          } else {
            resultList[i].picture = imgBaseUrl + resultList[i].picture;
          }
          resultList[i].yksl = 0; //已开数量
        }
        that.awardsList = resultList;
      });
    },
    // 随机获取用户信息
    stochasticUser() {
      let arr = that.userList;
      that.shuffleArray(arr);
      let result = arr.slice(0, 15);
      that.resList = result;
    },

    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    },

    // 开始抽奖
    startLottery() {
      if (that.awardsValue < 0 || !that.numValue) {
        this.$notify({
          title: "警告",
          message: "奖项或中奖数不能为空",
          type: "warning",
        });
        return;
      }
      that.isDisplayBox = true;
      let numValue = that.numValue; //输入
      let selectId = that.awardsList[that.indexImg].id;
      let awardsVal = parseInt(localStorage.getItem(selectId) || 0);
      let oldNum = awardsVal;
      if (awardsVal > 0) {
        awardsVal = parseInt(numValue) + awardsVal;
      } else {
        awardsVal = numValue;
      }
      let awardNum = that.awardsList[that.indexImg].num;
      if (awardsVal > awardNum) {
        this.$notify({
          title: "警告",
          message: "超过中奖数了",
          type: "warning",
        });
        return;
      }
      let data = {
        awardsId: that.awardsId,
      };
      openLottery(data).then((res) => {
        if (res.data.code !== 200) {
          this.$notify({
            title: "警告",
            message: res.data.msg,
            type: "warning",
          });
          return;
        }
        suiInterval = setInterval(() => {
          that.stochasticUser();
        }, 300);
        let result = res.data.data;
        // 0,15是动态
        that.winners = result.slice(oldNum, awardsVal);
        localStorage.setItem(selectId, awardsVal);
      });
    },

    // 停止抽奖
    stopLottery() {
      if (that.awardsValue < 0 || !that.numValue) {
        this.$notify({
          title: "警告",
          message: "奖项或中奖数不能为空",
          type: "warning",
        });
        return;
      }
      clearInterval(suiInterval);
      that.showWinners();
      that.isDisplayBox = false;
    },
  },
  mounted() {
    // this.showWinners();
  },
  created() {
    that = this;
    that.getenrollActivityUser();
    that.getenrollActivityAwards();
  },
};
</script>
<style lang="scss" scoped>
.home_view {
  width: 100vw;
  height: 100vh;
  background: url("../assets/index_bg.jpg") no-repeat;
  background-size: 100% 100%;
  .hottery_title {
    padding-top: 3rem;
    font-size: 4rem;
    color: #f8d5af;
    text-align: center;
    font-weight: bold;
  }
  .hottery_box {
    display: flex;
    margin-top: 3rem;
    color: #ffffff;

    .hb_left {
      width: 35%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .left_img {
        width: 100%;
        .images {
          width: 60%;
          height: 100%;
          border-radius: 5rem;
        }
      }
      .awards_box {
        font-size: 3rem;
        line-height: 1.78;
        .sub_title {
          display: flex;
          flex-direction: column;
          letter-spacing: 0.5rem;
          line-height: 1.13;
          .sub_tip {
            font-size: 1.5rem;
          }
        }
      }
      .awards_num {
        margin-top: 10px;
        font-size: 2.5rem;
        color: #f8d5af;
      }
      .select_box {
        display: flex;
        align-items: center;
        // width: 90%;
        margin: 1rem auto 0;
        .ele_select,
        .input_box {
          width: 150px;
          display: flex;
          align-items: center;
        }
        .input_box {
          width: 200px;
          margin-left: 1rem;
          .input_tip {
            width: 64%;
            font-size: 1.2rem;
          }
        }
      }
      .button_box {
        margin-top: 1rem;
      }
    }
    .hb_right {
      position: relative;
      flex: 1;
      .hottery_list {
        display: flex;
        flex-wrap: wrap;
        .hl_item {
          width: 28%;
          font-size: 3rem;
          font-weight: bold;
          display: flex;
          align-items: center;
          background: #f8d5af;
          margin-bottom: 4rem;
          justify-content: center;
          margin-right: 4rem;
          padding: 1rem 0;
          color: #271a0a;
          cursor: default;
          .hli_num {
            margin-top: 0.5rem;
            margin-left: 1rem;
          }
        }
      }
      .win_box {
        width: 95%;
        height: 680px;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        box-shadow: 0 0 200px #c4a071;
        // transform: rotateX(180deg);
        .win_title {
          font-size: 5rem;
          color: rgb(196, 160, 113);
          font-weight: 600;
          text-align: center;
        }
        .win_user {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .win_item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 2.5rem;
            width: 150px;
            height: 150px;
            padding: 8px;
            position: relative;
            // margin-right: 1.5rem;
            margin-bottom: 1.5rem;
            .win_name {
              margin-left: -20px;
              // overflow: hidden;
              // white-space: nowrap;
              // text-overflow: ellipsis;
              // -o-text-overflow: ellipsis;
            }
            &::after {
              content: "";
              width: 150px;
              height: 150px;
              display: inline-block;
              position: absolute;
              top: 0;
              left: 0;
              animation: foreverrotate linear infinite;
              animation-duration: 2s;
              background-image: url("../assets/xuanzhuan.png");
              background-size: 100% 100%;
            }
          }
        }
      }
    }
  }
}
/* 添加过渡效果的CSS */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

@keyframes foreverrotate {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(-1turn);
  }
}
</style>